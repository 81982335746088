import React, { useEffect, useState } from 'react';
import axiosInstance from '../utils/axiosInstance';
import { Link, useParams } from 'react-router-dom';
import LightGallery from 'lightgallery/react';
import lgZoom from 'lightgallery/plugins/zoom';
import lgVideo from 'lightgallery/plugins/video';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-video.css';

function EventDetails() {
    const { slugURL } = useParams();
    const [eventDetails, setEventDetails] = useState([]);
    const [eventImages, setEventImages] = useState([]);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchEventDetailsData = async () => {
            try {
                const response = await axiosInstance.get(`events/getEventBySlugURL/${slugURL}`);
                const response1 = await axiosInstance.get(`images/getEventImages/${slugURL}`);
                const fetchedData = response.data;
                const showImages = response1.data.images;

                setEventImages(showImages);
                setEventDetails([fetchedData]);
            } catch (error) {
                setError('Error fetching main project data');
                console.error('Error fetching main project data:', error);
            }
        };
        fetchEventDetailsData();
    }, [slugURL]);

    return (
        <div>
            <div className="insideBanner">
                <picture>
                    <source media="(min-width: 992px)" srcSet="../assets/images/events.jpg" />
                    <source media="(min-width: 768px)" srcSet="../assets/images/events-m.jpg" />
                    <img
                        src="../assets/images/events-m.jpg"
                        className="h-100 object-cover object-position-bottom rounded"
                        alt="Star Estate"
                    />
                </picture>
            </div>
            <div className="w-100">
                <div className="container-lg">
                    {eventDetails.map((events, index) => (
                        <div key={index} className="breadcrumbContainer" aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item">Media</li>
                                <li className="breadcrumb-item active"><Link to="/events">Events</Link></li>
                                <li className="breadcrumb-item active">{events.eventName}</li>
                            </ol>
                        </div>
                    ))}
                </div>
            </div>
            <div className="w-100 padding">
                <div className="container-lg">
                    <div className="heading mx-sm-auto text-sm-center">
                        <h3 className="mb-0">Events</h3>
                    </div>
                        <LightGallery plugins={[lgZoom, lgVideo]} mode="lg-fade">
                            {eventImages.map((event, index) => (
                                <div
                                    key={index}
                                    className="col-lg-3 col-md-4 col-6 gallery-item"
                                    data-src={`${axiosInstance.defaults.globalURL}${event.imagePath}`}
                                    data-sub-html={`<h4>${event.eventId}</h4>`}
                                >
                                    <img
                                        src={`${axiosInstance.defaults.globalURL}${event.imagePath}`}
                                        alt={event.eventName || 'Event Image'}
                                        className="img-fluid"
                                        style={{ width: '100%', height: '200px', objectFit: 'cover' }}
                                    />
                                </div>
                            ))}
                        </LightGallery>
                </div>
            </div>
        </div>
    );
}

export default EventDetails;
