import React, { useEffect, useState } from 'react';
import axiosInstance from '../utils/axiosInstance';
import { Link } from 'react-router-dom';
import LightGallery from 'lightgallery/react';
import lgZoom from 'lightgallery/plugins/zoom';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';

function Awards() {
    const [awards, setAwards] = useState([]);
    const [loading, setLoading] = useState(true); // Add loading state

    useEffect(() => {
        const fetchAwards = async () => {
            try {
                const response = await axiosInstance.get(`/award/getAwards`);
                const filteredAwards = response.data.filter(award => award.status === true);
                setAwards(filteredAwards);
                setLoading(false); // Set loading to false after data is fetched
            } catch (error) {
                console.error('Failed to fetch Awards', error);
                setLoading(false); // Set loading to false in case of an error
            }
        };

        fetchAwards();
    }, []);

    return (
        <div>
            <div className="insideBanner">
                <picture>
                    <source media="(min-width: 992px)" srcSet="assets/images/award.jpg" />
                    <source media="(min-width: 768px)" srcSet="assets/images/award-m.jpg" />
                    <img
                        src="assets/images/award-m.jpg"
                        className="h-100 object-cover object-position-bottom rounded"
                        alt="Star Estate"
                    />
                </picture>
            </div>

            <div className="w-100">
                <div className="container-lg">
                    <div className="breadcrumbContainer" aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to='/'>Home</Link></li>
                            <li className="breadcrumb-item">Media</li>
                            <li className="breadcrumb-item active">Awards & Recognitions</li>
                        </ol>
                    </div>
                </div>
            </div>

            <div className="w-100 padding">
                <div className="container-lg">
                    <div className="heading mx-sm-auto text-sm-center">
                        <h3 className="mb-0">Awards & Recognitions</h3>
                    </div>

                    {/* Show spinner while loading */}
                    {loading ? (
                        <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '200px' }}>
                            <div className="spinner-border text-primary" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    ) : (
                        <LightGallery plugins={[lgZoom]} mode="lg-fade" className="row gap-row">
                            {awards.map((award, index) => (
                                <div key={index} className="col-lg-4 col-sm-6 award-slide"
                                    data-src={`${axiosInstance.defaults.globalURL}${award.awardImage}`}
                                    data-sub-html={`<h4>${award.awardName}</h4>`}>
                                    <img
                                        src={`${axiosInstance.defaults.globalURL}${award.awardImage}`}
                                        alt={award.awardName || 'Award Image'}
                                        className="img-fluid"
                                        style={{ cursor: 'pointer', width: '100%', height: 'auto' }} // Set image dimensions
                                    />
                                </div>
                            ))}
                        </LightGallery>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Awards;
